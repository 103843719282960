import React from "react"
import SEO from "src/components/shared/seo"
import Layout, { Inner } from "src/components/shared/Layout"
import { graphql } from "gatsby"
import cx from "classnames"
import Button from "src/components/shared/Button"
import Illustration from "src/components/shared/Illustration"
import Typography from "src/components/shared/Typography"
import styles from "./prestation.styles.module.css"
import Turtles from "../components/shared/Turtles"

export const ServicePageTemplate = ({
  name,
  citation,
  citationAuthor,
  color,
  courseLogo,
  duration,
  difficulty,
  cardio,
  strengthening,
  images,
  benefits,
  html,
  preview,
}) => {
  const [featuredImage, ...imagesCollection] = images || []

  return (
    <Layout bannerImage="lotus">
      <SEO
        title={`${name} — Turtle Tonic — Aquabike • Aquagym • Leçon de natation`}
      />
      <Inner>
        <section className={cx(styles.Section, styles.Intro)}>
          <div>
            <Typography.h1 className={styles.Title}>{name}</Typography.h1>
            <Typography.h2 className={styles.Subtitle}>
              {duration}
            </Typography.h2>

            <Typography.p className={styles.Citation}>
              {citation}
              {citationAuthor && (
                <cite className={styles.Author}>{citationAuthor}</cite>
              )}
            </Typography.p>

            <div className={styles.Actions}>
              <Button style={{ backgroundColor: color }} href="reservation">
                Réserver un cours
              </Button>
              <Button
                style={{ borderColor: color, color }}
                bordered
                href="tarifs"
              >
                Voir les tarifs
              </Button>
            </div>
          </div>

          <div className={styles.CourseLogo}>
            <img src={courseLogo} alt={name} />
          </div>
        </section>

        {difficulty ? (
          <section className={cx(styles.Section, styles.Second)}>
            <Illustration
              withShadow
              withWave
              color={color}
              src={featuredImage && featuredImage.image}
              height={550}
              className={styles.Illustration}
            />
            <div className={styles.Difficulty}>
              <ul className={styles.List}>
                <li>
                  <span>Difficulté</span>{" "}
                  <Turtles className={styles.Turtles} number={difficulty} />
                </li>
                <li>
                  <span>Cardio</span>{" "}
                  <Turtles className={styles.Turtles} number={cardio} />
                </li>
                <li>
                  <span>Renforcement</span>{" "}
                  <Turtles className={styles.Turtles} number={strengthening} />
                </li>
              </ul>
              <Button
                style={{ backgroundColor: color }}
                className={styles.DifficultyButton}
                href="planning"
              >
                Voir le planning
              </Button>
            </div>
          </section>
        ) : null}

        {benefits && benefits.length && (
          <section className={cx(styles.Section, styles.Benefits)}>
            <Typography.h2 className={styles.Heading}>
              Bénéfices :
            </Typography.h2>
            <div className={styles.BenefitsList}>
              {benefits.map(({ image, description }) => (
                <div key={description} className={styles.Benefit}>
                  <img src={image} alt={description} />
                  <div>{description}</div>
                </div>
              ))}
            </div>
            <Button style={{ backgroundColor: color }} href="reservation">
              Réserver un cours
            </Button>
          </section>
        )}

        <section className={cx(styles.Section, styles.Images)}>
          {imagesCollection &&
            imagesCollection.map(({ image, description }) => (
              <div key={description} className={styles.ImageContainer}>
                <div
                  style={{ backgroundImage: `url(${image})` }}
                  className={styles.Image}
                />
                <div className={styles.Description}>{description}</div>
              </div>
            ))}
        </section>
      </Inner>

      {/* {!preview && (
        <>
          <section className={styles.Services} id="services">
            <Inner>
              <Typography.h1 className={styles.ServicesTitle}>
                Les autres prestations
              </Typography.h1>
              <Typography.p>
                Toutes ces prestations ont pour but d’apporter détente et
                bien-être et ne peuvent en aucun cas se substituer aux
                prescriptions médicales.
              </Typography.p>
              <FeaturedServices />
              <Button href="/cours">Voir tous les massages</Button>
            </Inner>
          </section>

          <div className={styles.ContactForm}>
            <Inner small id="contact">
              <ContactForm />
            </Inner>
          </div>
        </>
      )} */}
    </Layout>
  )
}

const ServicePage = ({ data }) => {
  console.log(data)
  const {
    markdownRemark: {
      frontmatter: {
        name,
        duration,
        citation,
        citationAuthor,
        color,
        courseLogo,
        difficulty,
        cardio,
        strengthening,
        images,
        benefits,
      },
      html,
    },
  } = data

  return (
    <ServicePageTemplate
      name={name}
      citation={citation}
      citationAuthor={citationAuthor}
      courseLogo={courseLogo}
      color={color}
      duration={duration}
      difficulty={difficulty}
      cardio={cardio}
      strengthening={strengthening}
      images={images}
      benefits={benefits}
      html={html}
    />
  )
}

export default ServicePage

export const presentationQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        citation
        citationAuthor
        color
        courseLogo
        duration
        difficulty
        cardio
        strengthening
        images {
          image
          description
        }
        benefits {
          image
          description
        }
      }
    }
  }
`

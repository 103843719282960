import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import styles from "./styles.module.css"
import Wave from "src/components/shared/Wave"

const Illustration = ({
  src,
  className,
  height,
  withWave,
  color,
  withShadow,
  waveClassName,
  imageClassName,
}) => (
  <div className={cx(styles.IllustrationContainer, className)}>
    <div
      className={cx(styles.Illustration, { [styles.WithShadow]: withShadow })}
    >
      <div
        className={styles.Border}
        style={
          color
            ? {
                backgroundColor: color,
              }
            : {}
        }
      ></div>
      <div
        style={{
          backgroundImage: `url(${src})`,
          height,
        }}
        className={cx(styles.Image, imageClassName)}
      />
      {withWave && (
        <div className={cx(styles.Wave, waveClassName)}>
          <Wave />
        </div>
      )}
    </div>
  </div>
)

Illustration.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  withWave: PropTypes.bool,
}

export default Illustration
